var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(" Visit Tutti Task UI: ")]),
      _c("v-card-text", [
        _vm.projectName
          ? _c(
              "div",
              [
                _c(
                  "v-row",
                  { attrs: { align: "center" } },
                  [
                    _c("v-col", { attrs: { cols: "2" } }, [
                      _c("b", [_vm._v("Production URL")]),
                      _vm._v(": "),
                    ]),
                    _c("v-col", { attrs: { cols: "10" } }, [
                      _vm.projectHasDiff
                        ? _c(
                            "div",
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "warning--text font-weight-medium",
                                },
                                [
                                  _vm._v(
                                    'Production URL is not available since "' +
                                      _vm._s(_vm.projectName) +
                                      '" seems to be changed since the last build.'
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    outlined: "",
                                    small: "",
                                    color: "warning",
                                    loading: _vm.rebuildingProject,
                                  },
                                  on: { click: _vm.rebuildProject },
                                },
                                [_vm._v("Rebuild now")]
                              ),
                            ],
                            1
                          )
                        : _c("div", [
                            _vm.alert.show
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { left: "", color: "error" } },
                                      [_vm._v("mdi-alert")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "error--text font-weight-medium",
                                      },
                                      [_vm._v(_vm._s(_vm.alert.message))]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "500px" } },
                                      [
                                        _c("v-text-field", {
                                          staticStyle: { "font-size": "12px" },
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            readonly: "",
                                            value: _vm.url,
                                            width: 400,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "copy-to-clipboard-btn",
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: {
                                                          "x-small": "",
                                                          text: _vm.url,
                                                        },
                                                      }
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            3908211290
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-2",
                                            attrs: {
                                              dark: "",
                                              color: "indigo darken-2",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openUrlInNewTab(
                                                  _vm.devUrl
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(" Start task "),
                                            _c(
                                              "v-icon",
                                              { attrs: { right: "" } },
                                              [_vm._v("mdi-open-in-new")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { attrs: { align: "center" } },
                  [
                    _c("v-col", { attrs: { cols: "2" } }, [
                      _c("b", [_vm._v("Development URL")]),
                      _vm._v(": "),
                    ]),
                    _c("v-col", { attrs: { cols: "10" } }, [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c(
                          "div",
                          { staticStyle: { width: "500px" } },
                          [
                            _c("v-text-field", {
                              staticStyle: { "font-size": "12px" },
                              attrs: {
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                readonly: "",
                                value: _vm.devUrl,
                                width: 400,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function () {
                                      return [
                                        _c("copy-to-clipboard-btn", {
                                          staticClass: "ml-1",
                                          attrs: {
                                            "x-small": "",
                                            text: _vm.devUrl,
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1113422829
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                attrs: { dark: "", color: "grey darken-2" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openUrlInNewTab(_vm.devUrl)
                                  },
                                },
                              },
                              [
                                _vm._v(" Start task "),
                                _c("v-icon", { attrs: { right: "" } }, [
                                  _vm._v("mdi-open-in-new"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _c("div", [_vm._v(" Select project first. ")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }