var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(" Projects ")]),
      _c("v-data-table", {
        attrs: {
          dense: "",
          "sort-desc": "",
          headers: _vm.headers,
          items: _vm.projectItems,
          "sort-by": "last_modified",
        },
        scopedSlots: _vm._u([
          {
            key: "item.numTemplates",
            fn: function ({ item }) {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "span",
                                _vm._g(_vm._b({}, "span", attrs, false), on),
                                [_vm._v(_vm._s(item.numTemplates))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(item.templates.join("<br>")),
                      },
                    }),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.last_modified",
            fn: function ({ item }) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.standardDateTimeFormat(item.last_modified)) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }