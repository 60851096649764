var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "mt-10 grey lighten-4" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("task-ui-urls-card", {
                attrs: { projectName: _vm.projectName, client: _vm.client },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [_c("project-list-card", { attrs: { client: _vm.client } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }